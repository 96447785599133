import { ExceptionRequestStatus } from 'API'

interface ExceptionsStatusProps {
  status: ExceptionRequestStatus
}

const ExceptionsStatus = ({ status }: ExceptionsStatusProps) => {
  const statusBackgroundColor = {
    [ExceptionRequestStatus.GRANTED]: 'bg-success',
    [ExceptionRequestStatus.DENIED]: 'bg-danger',
    [ExceptionRequestStatus.ERROR]: 'bg-danger',
    [ExceptionRequestStatus.PENDING]: 'bg-info'
  }[status]

  const label = {
    [ExceptionRequestStatus.GRANTED]: 'APPROVED',
    [ExceptionRequestStatus.DENIED]: 'DENIED',
    [ExceptionRequestStatus.ERROR]: 'ERROR',
    [ExceptionRequestStatus.PENDING]: 'PENDING'
  }[status]

  return (
    <div
      data-testid="exceptions-status"
      className={`w-max px-[14px] py-4 text-14 font-bold text-white ${statusBackgroundColor}`}
    >
      {label}
    </div>
  )
}

export default ExceptionsStatus
