import { PropsWithChildren, memo, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Map as HereMap,
  MapLayerIncidentsItem,
  MapLayerRoadRestrictionsItem,
  MapLayerSettings,
  MapLayerTrafficItem,
  MapSettingsDropdownHeader,
  MapTypeSettings,
  Route,
  SingleMapMarker,
  MapSettings
} from 'components/map/Map'
import { MapApi, MapLayer, Position } from 'components/map/types'
import {
  getFollowSelectedVehicle,
  getMapLayers,
  getShowHubs,
  setMapLayers,
  setShowHubs
} from 'store/slices/appSlice'
import { transformToRioPosition } from 'helper/position'
import { Hub, Mission, RoadSegmentStatus, Vehicle } from 'API'
import { getSeverityLevel, getRouteColor } from './utils'
import VehicleMarker from './VehicleMarker'
import { MapLayerHubItem } from './MapSettingsHubItem'
import { useBoundingBox } from './hooks/useBoundingBox'
import { DeviationWithVIN } from 'helper/types'
import { isDeepEqual } from 'remeda'
import HubMarker from './HubMarker'
import { getMapCredentials } from 'configuration'
import { DEFAULT_LOCALE } from 'store/i18n/lang'
import RoadSegment from '../road-segment-status/RoadSegment'
import { DEFAULT_ZOOM, ZOOMED_VEHICLE } from 'components/map/contants'

const mapCredentials = getMapCredentials()

interface MapProps {
  activeMission: Mission | undefined
  hubs?: Hub[]
  vehicles: Vehicle[]
  roadSegmentStatus?: RoadSegmentStatus[]
  deviations?: DeviationWithVIN[]
  selectedVehicle?: Vehicle
  center?: Position
  zoom?: number
}

const Map = ({
  hubs,
  vehicles,
  deviations,
  selectedVehicle,
  activeMission,
  roadSegmentStatus,
  center,
  zoom,
  children
}: PropsWithChildren<MapProps>) => {
  const mapApiRef = useRef<MapApi>()
  const mapLayers = useSelector(getMapLayers)
  const showHubs = useSelector(getShowHubs)
  const dispatch = useDispatch()
  const shouldFollowSelectedVehicle = useSelector(getFollowSelectedVehicle)
  const { boundingBox } = useBoundingBox(vehicles, hubs)
  const showRoute = useMemo(
    () =>
      !!selectedVehicle?.activeMissionId &&
      !!activeMission?.id &&
      selectedVehicle?.activeMissionId === activeMission?.id,
    [selectedVehicle?.activeMissionId, activeMission?.id]
  )

  useEffect(() => {
    const mapReference = mapApiRef.current?.map

    if (!mapReference || !selectedVehicle?.position) {
      return
    }

    if (!selectedVehicle) {
      mapReference.setZoom(DEFAULT_ZOOM)
      return
    }

    if (shouldFollowSelectedVehicle) {
      mapReference.getViewModel().setLookAtData({
        position: transformToRioPosition(selectedVehicle.position),
        zoom: ZOOMED_VEHICLE
      })
    }
  }, [selectedVehicle, shouldFollowSelectedVehicle])

  const trafficLayers = useMemo(
    () => [
      <MapSettingsDropdownHeader key="dividerTraffic" label="Traffic overlays" />,
      <MapLayerHubItem
        key="mapLayerHubsItem"
        isActive={showHubs}
        onToggle={() => dispatch(setShowHubs(!showHubs))}
      />,
      <MapLayerTrafficItem key="mapLayerTrafficItem" label="Traffic" />,
      <MapLayerIncidentsItem key="mapLayerIncidentsItem" label="Incidents" />,
      <MapLayerRoadRestrictionsItem key="mapLayerRoadRestrictionsItem" label="Road restrictions" />
    ],
    [showHubs, dispatch]
  )

  return (
    <HereMap
      credentials={mapCredentials}
      boundingBox={boundingBox}
      language={DEFAULT_LOCALE}
      mapLayer={mapLayers}
      enableWebGL
      center={center}
      zoom={zoom}
      enableDevicePixelRatio
      onMapLayerChange={(activeMapLayers: MapLayer[]) => dispatch(setMapLayers(activeMapLayers))}
      mapSettings={
        <MapSettings
          options={[
            <MapTypeSettings
              key="mapTypeSettings"
              tooltip="Change map type"
              dropdownHeaderText="Map views"
              defaultTypeLabel="Default view"
              truckTypeLabel="Truck view"
              terrainTypeLabel="Terrain view"
              satelliteTypeLabel="Satellite view"
              nightTypeLabel="Night view"
            />,
            <MapLayerSettings
              key="mapLayerSettingsTraffic"
              layers={trafficLayers}
              tooltip="Toggle various traffic overlays"
            />
          ]}
        />
      }
    >
      {(api: MapApi) => {
        mapApiRef.current = api

        return (
          <>
            {children}
            {vehicles.map((vehicle) => (
              <VehicleMarker
                key={vehicle.externalId + vehicle.vin}
                vehicle={vehicle}
                severity={getSeverityLevel(vehicle.vin, deviations)}
              />
            ))}

            {showHubs && hubs?.map((hub) => <HubMarker key={hub.id} hub={hub} />)}
            {showRoute && (
              <Route
                positions={activeMission?.route?.waypoints.map(transformToRioPosition) || []}
                startIcon={
                  <SingleMapMarker iconNames={['start']} markerColor="bg-map-marker-route" />
                }
                endIcon={
                  <SingleMapMarker iconNames={['finish']} markerColor="bg-map-marker-route" />
                }
                hasArrows={false}
                style={{
                  width: 5,
                  borderWidth: 2,
                  isBorderIncludedInWidth: false,
                  ...getRouteColor(activeMission?.state.value)
                }}
              />
            )}
            {roadSegmentStatus?.map((item) => (
              <RoadSegment key={item.id} mapApiRef={mapApiRef.current} data={item} />
            ))}
          </>
        )
      }}
    </HereMap>
  )
}

const arePropsEqual = (prevProps: MapProps, nextProps: MapProps) =>
  isDeepEqual(prevProps.roadSegmentStatus, nextProps.roadSegmentStatus) &&
  isDeepEqual(prevProps.hubs, nextProps.hubs) &&
  isDeepEqual(
    prevProps.vehicles.map((_) => _.position),
    nextProps.vehicles.map((_) => _.position)
  ) &&
  isDeepEqual(prevProps.deviations, nextProps.deviations) &&
  isDeepEqual(prevProps.activeMission, nextProps.activeMission) &&
  prevProps.selectedVehicle?.activeMissionId === nextProps.selectedVehicle?.activeMissionId &&
  prevProps.selectedVehicle?.vin === nextProps.selectedVehicle?.vin

export default memo(Map, arePropsEqual)
