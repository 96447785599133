import { faker } from '@faker-js/faker'
import { WAYPOINTS } from './constants'
import { PositionInput } from 'API'

const firstLocation = WAYPOINTS[0]

export const fakePosition = (): PositionInput => ({
  latitude: faker.location.latitude({
    max: firstLocation.latitude + 1,
    min: firstLocation.latitude + 0.2
  }),
  longitude: faker.location.longitude({
    max: firstLocation.longitude + 1,
    min: firstLocation.longitude + 0.2
  }),
  heading: faker.number.float({ min: 0, max: 360 }),
  altitude: faker.number.float({ min: -10, max: 360 })
})
