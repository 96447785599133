import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store/reducers'
import { Camera, Vehicle } from 'API'
import { BoundingBox, MapLayer, Position } from 'components/map/types'

export interface RouteLocation {
  id: string
  label: string
  position: Position
}

interface VehicleMapState {
  showHubs: boolean
  mapContainerSizeTimestamp: number
  mapLayers: MapLayer[]
  boundingBox?: BoundingBox
}

interface PagesState {
  vehicleDetails: {
    open: boolean
  }
}

export interface AppState {
  sessionExpiredAcknowledged: boolean
  map: VehicleMapState
  selectedVIN: Vehicle['vin'] | undefined
  followSelectedVehicle: boolean
  pages: PagesState
  isCameraStreamMaximized: boolean
  selectedCamera?: Camera
}

const initialState: AppState = {
  sessionExpiredAcknowledged: false,
  map: {
    boundingBox: undefined,
    showHubs: true,
    mapContainerSizeTimestamp: Date.now(),
    mapLayers: []
  },
  selectedVIN: undefined,
  followSelectedVehicle: false,
  pages: {
    vehicleDetails: {
      open: false
    }
  },
  isCameraStreamMaximized: false,
  selectedCamera: undefined
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    resetAppState: () => {
      return initialState
    },
    hideSessionExpiredDialog: (state: AppState) => {
      state.sessionExpiredAcknowledged = true
    },
    setSelectedVehicle: (
      state: AppState,
      action: PayloadAction<{ selectedVIN?: Vehicle['vin']; followSelectedVehicle: boolean }>
    ) => {
      state.selectedVIN = action.payload.selectedVIN
      state.followSelectedVehicle = action.payload.followSelectedVehicle
    },
    clearSelectedVIN: (state: AppState) => {
      state.selectedVIN = undefined
      state.followSelectedVehicle = false
    },
    setIsVehicleDetailsOpen: (state: AppState, action: PayloadAction<boolean>) => {
      state.pages.vehicleDetails = { ...state.pages.vehicleDetails, open: action.payload }
    },
    setBoundingBox: (state: AppState, action: PayloadAction<BoundingBox>) => {
      state.map.boundingBox = action.payload
    },
    setMapLayers: (state: AppState, action: PayloadAction<MapLayer[]>) => {
      state.map.mapLayers = action.payload
    },
    setShowHubs: (state: AppState, action: PayloadAction<boolean>) => {
      state.map.showHubs = action.payload
    },
    setIsCameraStreamMaximized: (state: AppState, action: PayloadAction<boolean>) => {
      state.isCameraStreamMaximized = action.payload
    },
    setSelectedCamera: (state: AppState, action: PayloadAction<Camera>) => {
      state.selectedCamera = action.payload
    }
  }
})

export const {
  resetAppState,
  hideSessionExpiredDialog,
  setSelectedVehicle,
  clearSelectedVIN,
  setIsVehicleDetailsOpen,
  setBoundingBox,
  setMapLayers,
  setShowHubs,
  setIsCameraStreamMaximized,
  setSelectedCamera
} = appSlice.actions

// map selectors
export const getBoundingBox = (state: RootState) => state.app.map.boundingBox
export const getMapContainerSizeTimestamp = (state: RootState) =>
  state.app.map.mapContainerSizeTimestamp
export const getMapLayers = (state: RootState) => state.app.map.mapLayers
export const getShowHubs = (state: RootState) => state.app.map.showHubs

export const getSessionExpiredAcknowledged = (state: RootState) =>
  state.app.sessionExpiredAcknowledged
export const getSelectedVIN = (state: RootState) => state.app.selectedVIN
export const getSelectedCamera = (state: RootState) => state.app.selectedCamera
export const getFollowSelectedVehicle = (state: RootState) => state.app.followSelectedVehicle
export const getIsCameraStreamMaximized = (state: RootState) => state.app.isCameraStreamMaximized

// window selectors
export const getIsVehicleDetailsOpen = (state: RootState) => state.app.pages.vehicleDetails.open

export default appSlice.reducer
