import { ExceptionResponse } from 'API'
import ExceptionsStatus from './ExceptionsStatus'
import { mapExceptionType } from './utils'
import DateTimeOrNoData from 'features/vehicle-key-data/DateTimeOrNoData'

interface ExceptionsRowProps {
  exception: ExceptionResponse
}

const ExceptionsRow = ({ exception }: ExceptionsRowProps) => {
  const segmentStart = exception.exceptionSegment.from
  const segmentEnd = exception.exceptionSegment.to
  const segmentDirection = exception.exceptionSegment.direction

  return (
    <tr data-testid="exceptions-row" className="items-center">
      <td>{exception.id}</td>
      <td>
        <DateTimeOrNoData value={exception.createdAt} />
      </td>
      <td>{mapExceptionType(exception.exceptionType)}</td>
      <td>
        <DateTimeOrNoData value={exception.exceptionStart} />
      </td>
      <td>
        <DateTimeOrNoData value={exception.exceptionEnd} />
      </td>
      <td>{segmentStart}</td>
      <td>{segmentEnd}</td>
      <td>{segmentDirection}</td>
      <td>
        <ExceptionsStatus status={exception.status} />
      </td>
      <td className="w-[10%]" />
    </tr>
  )
}

export default ExceptionsRow
