import { createApi } from '@reduxjs/toolkit/query/react'
import { HealthStatusInformationSchema } from 'APIzod'
import { client } from 'graphql/client'
import { getHealthStatus } from 'graphql/queries'
import { HealthStatusInformation } from 'API'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { errorHandler } from './errors'

const getHealthStatusFn = async () => {
  try {
    const { data } = await client.graphql({ query: getHealthStatus })
    return { data: HealthStatusInformationSchema().parse(data.getHealthStatus) }
  } catch (error) {
    throw errorHandler('Unexpected error occurred while getting the health status', error)
  }
}

export const systemHealthApi = createApi({
  reducerPath: 'systemHealth',
  baseQuery: graphqlRequestBaseQuery({ url: '/graphql' }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getHealthStatus: builder.query<HealthStatusInformation, string>({
      queryFn: getHealthStatusFn
    })
  })
})

export const { useGetHealthStatusQuery } = systemHealthApi
