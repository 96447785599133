import Divider from 'components/content/Divider'
import { RoadSegmentRestrictionCause, RoadSegmentStatus } from 'API'
import DateTime from 'components/content/DateTime'

interface RoadSegmentStatusInfoProps {
  data: RoadSegmentStatus | null
}

const RoadSegmentStatusInfo = ({ data }: RoadSegmentStatusInfoProps) => {
  if (!data) {
    return null
  }

  const sectionHeaderClassName = 'mb-5 mt-10 text-10/12 font-bold text-gray-dark'

  const toCauseDisplayValues = (cause: RoadSegmentRestrictionCause) => {
    return {
      [RoadSegmentRestrictionCause.ROADWORK]: 'roadwork',
      [RoadSegmentRestrictionCause.ACCIDENT]: 'accident',
      [RoadSegmentRestrictionCause.CONGESTION]: 'congestion',
      [RoadSegmentRestrictionCause.GENERAL]: 'general',
      [RoadSegmentRestrictionCause.OBSTRUCTION]: 'obstruction',
      [RoadSegmentRestrictionCause.ROAD_CLOSURE]: 'road closure'
    }[cause]
  }

  const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

  const capitalizeFirstItem = (s: string, index: number) => (index === 0 ? capitalize(s) : s)

  return (
    <div className="-mb-15 -ml-15 -mr-25 w-[240px]">
      <div className="px-15 pb-15">
        <span className="rioglyph rioglyph-road-restrictions text-[28px] text-restrictions" />
        <div className="mt-10 text-16 font-bold">
          <p>Road restriction{data.restrictionCauses.length > 1 ? 's' : ''}:</p>
          <p>
            {data.restrictionCauses.map(toCauseDisplayValues).map(capitalizeFirstItem).join(', ')}
          </p>
        </div>
      </div>
      <Divider className="p-0" />
      <div className="p-15 text-12/14 font-medium">
        <p className={sectionHeaderClassName}>Restriction period</p>
        <div className="mb-5 flex">
          <p>Starts at&nbsp;</p>
          <DateTime date={new Date(data.restrictionStart)} />
        </div>
        {data.restrictionEnd && (
          <div className="flex">
            <p>Ends at&nbsp;</p>
            <DateTime date={new Date(data.restrictionEnd)} />
          </div>
        )}
        <p className={sectionHeaderClassName}>Segment</p>
        <p>{`From ${data.segment.from} to ${data.segment.to}`}</p>
        <p className={sectionHeaderClassName}>Source</p>
        <p>{data.source}</p>
        <p className={sectionHeaderClassName}>Last update</p>
        <DateTime date={new Date(data.reportedFromSourceAt)} />
      </div>
    </div>
  )
}

export default RoadSegmentStatusInfo
