import { ComponentProps } from 'react'
import { Severity } from 'API'
import ErrorIcon from 'components/assets/danger_icon.svg?react'
import WarningIcon from 'components/assets/warning_icon.svg?react'
import { assertNever } from 'helper/assertNever'

type SVGPropTypes = ComponentProps<typeof ErrorIcon>

interface DeviationIconProps extends SVGPropTypes {
  severity: Severity
}

const DeviationIcon = ({ severity, ...restOfProps }: DeviationIconProps) => {
  switch (severity) {
    case Severity.ERROR:
      return <ErrorIcon {...restOfProps} />

    case Severity.WARNING:
      return <WarningIcon {...restOfProps} />

    default:
      assertNever(severity, 'DeviationIcon')
      break
  }
}

export default DeviationIcon
