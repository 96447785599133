import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store/reducers'

export interface ArchiveMissionState {
  confirmationDialogOpen: boolean
  errorDialogOpen: boolean
  isAwaitingResponse: boolean
}

const initialState: ArchiveMissionState = {
  confirmationDialogOpen: false,
  errorDialogOpen: false,
  isAwaitingResponse: false
}

export const archiveMissionSlice = createSlice({
  name: 'archiveMission',
  initialState,
  reducers: {
    setConfirmationDialogOpen: (state: ArchiveMissionState, action: PayloadAction<boolean>) => {
      state.confirmationDialogOpen = action.payload
    },
    setErrorDialogOpen: (state: ArchiveMissionState, action: PayloadAction<boolean>) => {
      state.errorDialogOpen = action.payload
    },
    setIsAwaitingResponse: (state: ArchiveMissionState, action: PayloadAction<boolean>) => {
      state.isAwaitingResponse = action.payload
    }
  }
})

export const { setConfirmationDialogOpen, setIsAwaitingResponse, setErrorDialogOpen } =
  archiveMissionSlice.actions

export const getConfirmationDialogOpen = (state: RootState) =>
  state.archiveMission.confirmationDialogOpen
export const getErrorDialogOpen = (state: RootState) => state.archiveMission.errorDialogOpen
export const getIsAwaitingResponse = (state: RootState) => state.archiveMission.isAwaitingResponse

export default archiveMissionSlice.reducer
