import { Component, ErrorInfo, FC } from 'react'
import { ErrorFallbackProps } from './feedback/ErrorFallback'

export type FallbackComponent = FC<ErrorFallbackProps>
interface Props extends Partial<ErrorFallbackProps> {
  FallbackComponent: FallbackComponent
}

interface State {
  error?: unknown
}

class ErrorBoundary extends Component<Props, State> {
  override state = { error: undefined }

  static getDerivedStateFromError(error: unknown) {
    return { error }
  }

  public override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('[ErrorBoundary]: Uncaught error.', error, errorInfo)
  }
  override render() {
    const { error } = this.state
    const { FallbackComponent, children, error: forwardedError, containerClassName } = this.props
    const hasError = !!error || !!forwardedError
    return hasError ? (
      <FallbackComponent error={error ?? forwardedError} containerClassName={containerClassName} />
    ) : (
      children
    )
  }
}

export default ErrorBoundary
