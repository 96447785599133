import { FormFieldProps } from './types'

const FormFieldInput = ({
  type,
  min,
  placeholder,
  name,
  register,
  error,
  label,
  valueAsNumber,
  required
}: FormFieldProps) => (
  <div className={`form-group mb-0 ${error && 'has-error has-feedback'}`}>
    <label htmlFor={name}>{label}</label>
    <input
      className="form-control"
      min={min}
      required={required}
      type={type}
      placeholder={placeholder}
      {...(register &&
        register(name, {
          valueAsNumber,
          setValueAs: (v) => {
            if (type !== 'date' || !v) {
              return v
            }
            return new Date(v).toISOString()
          }
        }))}
    />
    {error && (
      <>
        <span className="form-control-feedback rioglyph rioglyph-error-sign" />
        <span className="help-block">
          <span>{error.message}</span>
        </span>
      </>
    )}
  </div>
)
export default FormFieldInput
