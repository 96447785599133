import RIONoData from '@rio-cloud/rio-uikit/NoData'
import { PropsWithTestId } from '../types'

interface NoDataProps {
  text: string
  className?: string
}

/**
 * The NoData component is used to display a message when there is no data available
 */
const NoData = ({ text, className, 'data-testid': dataTestId }: PropsWithTestId<NoDataProps>) => {
  return <RIONoData data-testid={dataTestId} text={text} className={className} />
}

export default NoData
