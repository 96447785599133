import { HealthStatus } from 'API'
import { useGetHealthStatusQuery } from 'services/systemHealth'

export const useCheckHealthStatus = () => {
  const {
    data: healthStatus,
    isError: systemHealthError,
    isLoading
  } = useGetHealthStatusQuery('GetHealthStatus', { pollingInterval: 10_000 })

  return {
    isSystemOk: (healthStatus?.status === HealthStatus.OK && !systemHealthError) || isLoading
  }
}
