import RIODivider from '@rio-cloud/rio-uikit/Divider'

interface DividerProps {
  color?: 'darkest' | 'darker' | 'dark' | 'gray' | 'light' | 'lighter' | 'lightest'
  spacing?: number
  className?: string
}

const Divider = ({ color, spacing, className }: DividerProps) => {
  return <RIODivider dividerColor={color} spacing={spacing} className={className} />
}

export default Divider
