import { ExceptionResponse } from 'API'
import ExceptionsRow from './ExceptionsRow'
import ExceptionsRowSkeleton from './ExceptionsRowSkeleton'
import { TableHeaders } from './utils'

interface ExceptionsTableProps {
  exceptions?: ExceptionResponse[]
  isLoading?: boolean
}

export const tableHeaders: TableHeaders = {
  id: 'ID',
  createdAt: 'Created at',
  exceptionType: 'Exception Type',
  periodStart: 'Period Start',
  periodEnd: 'Period End',
  from: 'From',
  to: 'To',
  direction: 'Direction',
  status: 'Status',
  _: ''
}

const ExceptionsTable = ({ exceptions, isLoading }: ExceptionsTableProps) => {
  return (
    <table data-testid="exceptions-table" className="table-head-filled table-bordered table">
      <thead>
        <tr>
          {Object.values(tableHeaders).map((label) => (
            <th key={label}>{label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <ExceptionsRowSkeleton />
        ) : (
          exceptions?.map((_) => <ExceptionsRow key={_.id} exception={_} />)
        )}
      </tbody>
    </table>
  )
}

export default ExceptionsTable
