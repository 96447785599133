import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store/reducers'

export interface StartMissionState {
  confirmationDialogOpen: boolean
  errorDialogOpen: boolean
  isAwaitingResponse: boolean
}

const initialState: StartMissionState = {
  confirmationDialogOpen: false,
  errorDialogOpen: false,
  isAwaitingResponse: false
}

export const startMissionSlice = createSlice({
  name: 'startMission',
  initialState,
  reducers: {
    setConfirmationDialogOpen: (state: StartMissionState, action: PayloadAction<boolean>) => {
      state.confirmationDialogOpen = action.payload
    },
    setErrorDialogOpen: (state: StartMissionState, action: PayloadAction<boolean>) => {
      state.errorDialogOpen = action.payload
    },
    setIsAwaitingResponse: (state: StartMissionState, action: PayloadAction<boolean>) => {
      state.isAwaitingResponse = action.payload
    }
  }
})

export const { setConfirmationDialogOpen, setIsAwaitingResponse, setErrorDialogOpen } =
  startMissionSlice.actions

export const getConfirmationDialogOpen = (state: RootState) =>
  state.startMission.confirmationDialogOpen
export const getErrorDialogOpen = (state: RootState) => state.startMission.errorDialogOpen
export const getIsAwaitingResponse = (state: RootState) => state.startMission.isAwaitingResponse

export default startMissionSlice.reducer
