import { Controller } from 'react-hook-form'
import RIOSelect from '@rio-cloud/rio-uikit/Select'
import { FormFieldProps } from './types'

const FormFieldSelect = ({
  placeholder,
  name,
  options,
  error,
  label,
  control,
  onChange
}: FormFieldProps & { onChange: () => void }) => (
  <div className={`form-group mb-0 ${error && 'has-error has-feedback'}`}>
    <label htmlFor={name}>{label}</label>
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <RIOSelect
          id={name}
          placeholder={placeholder}
          options={options}
          onChange={(item) => {
            field.onChange(item?.id)
            onChange()
          }}
        />
      )}
    />
    {error && (
      <span className="help-block">
        <span>{error.message}</span>
      </span>
    )}
  </div>
)
export default FormFieldSelect
