import ContentLoader from 'components/content/ContentLoader'
import { tableHeaders } from './ExceptionsTable'

const ExceptionsRowSkeleton = () => {
  const columns = Object.keys(tableHeaders).length - 1 // last column is just for spacing
  return (
    <tr data-testid="exceptions-table-loading" className="items-center">
      {[...Array(columns)].map((_) => (
        <td key={_}>
          <ContentLoader height="14px" width="100%" />
        </td>
      ))}
      <td className="w-[10%]" />
    </tr>
  )
}

export default ExceptionsRowSkeleton
