import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store/reducers'
import { Vehicle } from 'API'

interface MRMState {
  /* isAwaitingAnswer state is being used to give feedback to the user when there is delay between the Command trigger
   * and the response via Websocket message.
   */
  isAwaitingAnswer: boolean
  isSendingCommand: boolean
  mrmConfirmationDialogOpen: boolean
  releaseConfirmationDialogOpen: boolean
  errorDialogOpen: boolean
}

type PartialMRMState = Partial<MRMState>

const initialState: Record<Vehicle['vin'], MRMState> = {}

export const mrmSlice = createSlice({
  name: 'mrm',
  initialState,
  reducers: {
    setIsSendingCommand: (
      state: Record<string, PartialMRMState>,
      action: PayloadAction<{ vin: Vehicle['vin']; isSendingCommand: boolean }>
    ) => {
      state[action.payload.vin] = {
        ...state[action.payload.vin],
        isSendingCommand: action.payload.isSendingCommand
      }
    },
    setIsAwaitingAnswer: (
      state: Record<string, PartialMRMState>,
      action: PayloadAction<{ vin: Vehicle['vin']; isAwaitingAnswer: boolean }>
    ) => {
      state[action.payload.vin] = {
        ...state[action.payload.vin],
        isAwaitingAnswer: action.payload.isAwaitingAnswer
      }
    },
    setIsMRMConfirmationDialogOpen: (
      state: Record<string, PartialMRMState>,
      action: PayloadAction<{ vin: Vehicle['vin']; mrmConfirmationDialogOpen: boolean }>
    ) => {
      state[action.payload.vin] = {
        ...state[action.payload.vin],
        mrmConfirmationDialogOpen: action.payload.mrmConfirmationDialogOpen
      }
    },
    setIsReleaseConfirmationDialogOpen: (
      state: Record<string, PartialMRMState>,
      action: PayloadAction<{ vin: Vehicle['vin']; releaseConfirmationDialogOpen: boolean }>
    ) => {
      state[action.payload.vin] = {
        ...state[action.payload.vin],
        releaseConfirmationDialogOpen: action.payload.releaseConfirmationDialogOpen
      }
    },
    setIsErrorDialogOpen: (
      state: Record<string, PartialMRMState>,
      action: PayloadAction<{ vin: Vehicle['vin']; errorDialogOpen: boolean }>
    ) => {
      state[action.payload.vin] = {
        ...state[action.payload.vin],
        errorDialogOpen: action.payload.errorDialogOpen
      }
    }
  }
})

export const {
  setIsMRMConfirmationDialogOpen,
  setIsReleaseConfirmationDialogOpen,
  setIsErrorDialogOpen,
  setIsAwaitingAnswer,
  setIsSendingCommand
} = mrmSlice.actions

export const getIsSendingCommand = (state: RootState) =>
  !!state.mrm[String(state.app.selectedVIN)]?.isSendingCommand
export const getIsAwaitingAnswer = (state: RootState) =>
  !!state.mrm[String(state.app.selectedVIN)]?.isAwaitingAnswer
export const getIsMRMConfirmationDialogOpen = (state: RootState) =>
  !!state.mrm[String(state.app.selectedVIN)]?.mrmConfirmationDialogOpen
export const getIsReleaseConfirmationDialogOpen = (state: RootState) =>
  !!state.mrm[String(state.app.selectedVIN)]?.releaseConfirmationDialogOpen
export const getIsErrorDialogOpen = (state: RootState) =>
  !!state.mrm[String(state.app.selectedVIN)]?.errorDialogOpen
export const getIsDialogOpen = (state: RootState) =>
  getIsReleaseConfirmationDialogOpen(state) ||
  getIsMRMConfirmationDialogOpen(state) ||
  getIsErrorDialogOpen(state)

export default mrmSlice.reducer
