import RIOSpinner from '@rio-cloud/rio-uikit/Spinner'
import classNames from 'classnames'

interface SpinnerProps {
  large?: boolean
  show?: boolean
  text?: string
  isFullScreen?: boolean
  isFullSized?: boolean
  isInverse?: boolean
}

interface LargeSpinnerProps {
  borderWidth?: number
  size?: number
}

/**
 * TODO: Either fully use tailwind classes or request big spinner from UIKit.
 * We're relying on the UIKit implementation of .spinner CSS class.
 *
 * @param {number} borderWidth Width of border in pixels
 * @param {number} size Size of the Spinner in pixels
 */
const LargeSpinner = ({ borderWidth = 8, size = 200 }: LargeSpinnerProps) => {
  const borderWidthClass = `border-[${borderWidth}px]`
  const dimensions = `size-[${size}px]`
  return (
    <div data-testid="spinner" className={classNames('spinner', dimensions)}>
      <div className={borderWidthClass} />
      <div className={borderWidthClass} />
      <div className={borderWidthClass} />
      <div className={borderWidthClass} />
    </div>
  )
}

const Spinner = ({
  large = false,
  show,
  text,
  isFullScreen = false,
  isInverse = false,
  isFullSized = false,
  size,
  borderWidth
}: SpinnerProps & LargeSpinnerProps) => {
  if (large) {
    return <LargeSpinner size={size} borderWidth={borderWidth} />
  }
  return (
    <RIOSpinner
      show={show}
      isFullSized={isFullSized}
      text={text}
      isFullScreen={isFullScreen}
      isInverse={isInverse}
    />
  )
}

export default Spinner
