import { formatDate, formatTime } from '../../helper/time'

interface DateTimeProps {
  date: Date
  className?: string
}

const DateTime = ({ date, className }: DateTimeProps) => (
  <span className={className}>
    <span>{formatTime(date)}</span>
    <span>&nbsp;-&nbsp;</span>
    <span>{formatDate(date)}</span>
  </span>
)

export default DateTime
