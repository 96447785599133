import ExceptionsTable from 'features/exception-requests/ExceptionsTable'
import { useListExceptionsQuery, useRequestExceptionMutation } from 'services/roadRestrictions'
import RequestExceptionDialog from 'features/exception-requests/RequestExceptionDialog'
import { useDispatch, useSelector } from 'react-redux'
import {
  getIsDialogOpen,
  getIsErrorDialogOpen,
  setIsDialogOpen,
  setIsErrorDialogOpen
} from '../store/slices/requestExceptionsSlice'
import { RequestExceptionsFormData } from 'features/exception-requests/types'
import Button from 'components/actions/Button'
import ErrorDialog from 'components/overlays/ErrorDialog'

const ExceptionsPage = () => {
  const dispatch = useDispatch()
  const isDialogOpen = useSelector(getIsDialogOpen)
  const isErrorDialogOpen = useSelector(getIsErrorDialogOpen)
  const openDialog = () => dispatch(setIsDialogOpen(true))
  const closeDialog = () => dispatch(setIsDialogOpen(false))
  const openErrorDialog = () => dispatch(setIsErrorDialogOpen(true))
  const closeErrorDialog = () => dispatch(setIsErrorDialogOpen(false))

  const { data, isLoading: isFetchingExceptions } = useListExceptionsQuery('ListExceptions', {
    refetchOnMountOrArgChange: true
  })

  const [requestException, { isLoading, isError }] = useRequestExceptionMutation({
    fixedCacheKey: 'requestException'
  })

  const handleConfirm = async (formData: RequestExceptionsFormData) => {
    try {
      await requestException({ input: formData }).unwrap()
    } catch (e) {
      console.error(e)
      openErrorDialog()
    } finally {
      closeDialog()
    }
  }

  return (
    <main
      className="flex h-full flex-col bg-white px-50 pt-50"
      data-testid="exceptions-page-content"
    >
      <div>
        <h4 className="font-bold">Driving restrictions exception requests</h4>
        <Button onClick={openDialog} style="primary" className="mt-25">
          <span className="rioglyph rioglyph-plus" /> New request
        </Button>
      </div>
      <div className="mt-25">
        <ExceptionsTable exceptions={data} isLoading={isFetchingExceptions} />
      </div>
      <div>{/* TODO: exception requests table */}</div>
      <RequestExceptionDialog
        show={isDialogOpen}
        isLoading={isLoading}
        isError={isError}
        onCancel={closeDialog}
        onConfirm={handleConfirm}
      />
      <ErrorDialog show={isErrorDialogOpen} onConfirm={closeErrorDialog} />
    </main>
  )
}

export default ExceptionsPage
