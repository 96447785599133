import * as Comlink from 'comlink'

interface Clock {
  subscribe: (cb: (date: Date) => void) => void
}

const worker = new SharedWorker(new URL('worker.js', import.meta.env.VITE_HOME_ROUTE), {
  name: 'ClockWorker'
})

const clock = Comlink.wrap<Clock>(worker.port)

export default clock
