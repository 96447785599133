import { createApi } from '@reduxjs/toolkit/query/react'
import { MissionSchema } from 'APIzod'
import { client } from 'graphql/client'
import {
  ArchiveMissionInput,
  AssignMissionInput,
  CancelMissionInput,
  ResetMissionInput,
  Mission
} from 'API'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { getMission } from 'graphql/queries'
import { onUpdateMission } from '../graphql/subscriptions'
import { errorHandler } from './errors'
import { archiveMission, assignMission, cancelMission, resetMission } from 'graphql/mutations'

const getMissionQueryFn = async (id: string) => {
  try {
    const { data } = await client.graphql({ query: getMission, variables: { id } })
    return { data: MissionSchema().parse(data.getMission) }
  } catch (error) {
    throw errorHandler('Unexpected error occurred while fetching mission', error)
  }
}

export const missionApi = createApi({
  reducerPath: 'mission',
  baseQuery: graphqlRequestBaseQuery({ url: '/graphql' }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getMission: builder.query<Mission, string>({
      queryFn: getMissionQueryFn
    }),
    onMissionSubscription: builder.query<Mission, string>({
      queryFn: getMissionQueryFn,
      onCacheEntryAdded: async (id, { cacheDataLoaded, cacheEntryRemoved, updateCachedData }) => {
        const updateCache = (data: Mission) => {
          const mission = MissionSchema().parse(data)
          updateCachedData(() => mission)
        }

        await cacheDataLoaded
        if (import.meta.env.DEV) {
          // mockSubscription({
          //   timeOutMs: 10_000,
          //   maxIterations: 10,
          //   mockFn: mockMission,
          //   callback: (data) => {
          //     const updatedMission = MissionSchema().parse(data.onUpdateMission)
          //     updateCache(updatedMission)
          //   }
          // })
          // registerMissionsDevTools((data) => {
          //   updateCache(data.onMissionUpdate)
          // })
          // await cacheEntryRemoved
        } else {
          const connection = client
            .graphql({ query: onUpdateMission, variables: { id } })
            .subscribe({
              next: ({ data }) => {
                if (!data.onUpdateMission) {
                  console.error(
                    '[onMissionSubscription]: Please make sure all the attributes are returned in the mutation'
                  )
                }
                updateCache(data.onUpdateMission)
              },
              error: (error) => console.warn(error)
            })
          cacheEntryRemoved
            .then(() => {
              connection.unsubscribe()
            })
            .catch((error) => console.error(error))
        }
      }
    }),
    assignMission: builder.mutation<Mission, AssignMissionInput>({
      queryFn: async (variables) => {
        try {
          const { data } = await client.graphql({
            query: assignMission,
            variables: {
              input: variables
            }
          })
          return { data: MissionSchema().parse(data.assignMission) }
        } catch (error) {
          throw errorHandler(
            `Unexpected error occurred while assigning the mission with vin: ${variables.vin} and predefinedRouteId ${variables.predefinedRouteId}`,
            error
          )
        }
      }
    }),
    cancelMission: builder.mutation<Mission, CancelMissionInput>({
      queryFn: async (variables) => {
        try {
          const { data } = await client.graphql({
            query: cancelMission,
            variables: {
              input: variables
            }
          })
          return { data: MissionSchema().parse(data.cancelMission) }
        } catch (error) {
          throw errorHandler(
            `Unexpected error occurred while canceling the mission with id: ${variables.missionId}`,
            error
          )
        }
      }
    }),
    resetMission: builder.mutation<Mission, ResetMissionInput>({
      queryFn: async (variables) => {
        try {
          const { data } = await client.graphql({
            query: resetMission,
            variables: {
              input: variables
            }
          })
          return { data: MissionSchema().parse(data.resetMission) }
        } catch (error) {
          throw errorHandler(
            `Unexpected error occurred while resetting the mission with id: ${variables.missionId}`,
            error
          )
        }
      }
    }),
    archiveMission: builder.mutation<Mission, ArchiveMissionInput>({
      queryFn: async (variables) => {
        try {
          const { data } = await client.graphql({
            query: archiveMission,
            variables: {
              input: variables
            }
          })
          return { data: MissionSchema().parse(data.archiveMission) }
        } catch (error) {
          throw errorHandler(
            `Unexpected error occurred while archiving the mission with id: ${variables.missionId}`,
            error
          )
        }
      }
    })
  })
})

export const {
  useGetMissionQuery,
  useOnMissionSubscriptionQuery,
  useAssignMissionMutation,
  useCancelMissionMutation,
  useResetMissionMutation,
  useArchiveMissionMutation
} = missionApi
