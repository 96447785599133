import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { RoadSegmentDirection, RoadSegmentRestrictionCause } from 'API'
import FormFieldInput from './FormFieldInput'
import FormFieldSelect from './FormFieldSelect'
import FormFieldDateTimeInput from './FormFieldDateTimeInput'
import { RequestExceptionsFormData } from './types'
import { ExceptionRequestInputSchema } from '../../APIzod'
import { mapDirection, mapExceptionType } from './utils'
import { useState } from 'react'
import { SelectOption } from 'components/forms/types'
import Button from 'components/actions/Button'

interface RequestExceptionFormProps {
  onSubmit: (data: RequestExceptionsFormData) => void
  onCancel: () => void
  isLoading: boolean
}

const RequestExceptionForm = ({ onSubmit, onCancel, isLoading }: RequestExceptionFormProps) => {
  const defaultValues = {
    exceptionType: undefined,
    exceptionStart: undefined,
    exceptionEnd: undefined,
    exceptionSegment: {
      from: undefined,
      to: undefined,
      direction: undefined
    }
  }

  const [canSubmit, setCanSubmit] = useState(false)

  const { register, handleSubmit, formState, getValues, control } =
    useForm<RequestExceptionsFormData>({
      resolver: zodResolver(ExceptionRequestInputSchema()),
      defaultValues
    })

  const checkValues = () => {
    setCanSubmit(
      getValues([
        'exceptionType',
        'exceptionStart',
        'exceptionSegment.direction',
        'exceptionSegment.from',
        'exceptionSegment.to'
      ]).every((value) => value !== undefined)
    )
  }
  const exceptionTypeOptions: SelectOption[] = Object.values(RoadSegmentRestrictionCause).map(
    (value) => ({
      id: value,
      label: mapExceptionType(value)
    })
  )
  const directionOptions: SelectOption[] = Object.values(RoadSegmentDirection).map((value) => ({
    id: value,
    label: mapDirection(value)
  }))

  return (
    <form onChange={checkValues} onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-10 p-20 pb-15">
        <FormFieldSelect
          type="select"
          control={control}
          onChange={checkValues}
          label="Exception Type"
          placeholder="Please select..."
          name="exceptionType"
          error={formState.errors.exceptionType}
          options={exceptionTypeOptions}
        />
        <div className="flex w-full gap-15">
          <div className="w-1/2">
            <FormFieldDateTimeInput
              type="date"
              required
              onChange={checkValues}
              control={control}
              label="Exception Start"
              placeholder="Select date and time"
              name="exceptionStart"
              min={new Date().toISOString().split('T')[0]}
              error={formState.errors.exceptionStart}
            />
          </div>
          <div className="w-1/2">
            <FormFieldDateTimeInput
              type="date"
              control={control}
              required={false}
              onChange={checkValues}
              label="Exception End"
              placeholder="Select date and time"
              name="exceptionEnd"
              min={new Date().toISOString().split('T')[0]}
              error={formState.errors.exceptionEnd}
            />
          </div>
        </div>
        <div className="flex w-full gap-15">
          <div className="w-1/2">
            <FormFieldInput
              label="From"
              type="number"
              placeholder="0"
              min={0}
              required
              name="exceptionSegment.from"
              valueAsNumber
              register={register}
              error={formState.errors.exceptionSegment?.from}
            />
          </div>
          <div className="w-1/2">
            <FormFieldInput
              type="number"
              label="To"
              placeholder="0"
              valueAsNumber
              required
              min={0}
              name="exceptionSegment.to"
              register={register}
              error={formState.errors.exceptionSegment?.to}
            />
          </div>
        </div>
        <FormFieldSelect
          type="select"
          label="Direction"
          control={control}
          onChange={checkValues}
          placeholder="Please select..."
          name="exceptionSegment.direction"
          register={register}
          error={formState.errors.exceptionSegment?.direction}
          options={directionOptions}
        />
      </div>
      <hr className="mb-20" />
      <div className="flex justify-end gap-10 p-20 pt-0">
        <Button
          disabled={isLoading}
          data-testid="request-exception-dialog-cancel"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading || !canSubmit}
          type="submit"
          data-testid="request-exception-dialog-confirm"
          style="primary"
        >
          {isLoading ? (
            <>
              <span className="rioglyph rioglyph-spinner animate-spin" />
              Sending request
            </>
          ) : (
            <>
              <span className="rioglyph rioglyph-send" />
              Send request
            </>
          )}
        </Button>
      </div>
    </form>
  )
}

export default RequestExceptionForm
