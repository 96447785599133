import NotificationsContainer from 'components/content/Notifications'
import ApplicationLayout from 'components/ApplicationLayout'
import AppHeader from 'features/header/AppHeader'
import { Outlet } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { DEFAULT_LOCALE } from 'store/i18n/lang'
import { getDisplayMessages, getLocale } from 'store/i18n/langSlice'
import { useAppSelector } from 'store/setup/hooks'
import SystemNotification from 'features/system-health/SystemNotification'
import { useCheckHealthStatus } from 'hooks/useCheckHealthStatus'

const App = () => {
  const userLocale = useAppSelector(getLocale)
  const displayMessages = useAppSelector(getDisplayMessages)
  const { isSystemOk } = useCheckHealthStatus()

  if (!displayMessages || !userLocale) {
    return null
  }

  return (
    <IntlProvider
      defaultLocale={DEFAULT_LOCALE}
      key={userLocale}
      locale={userLocale}
      messages={displayMessages}
    >
      <ApplicationLayout>
        <ApplicationLayout.Header>
          <AppHeader />
          {!isSystemOk && (
            <div className="absolute top-50 z-[999] max-h-[45px] w-full">
              <SystemNotification />
            </div>
          )}
        </ApplicationLayout.Header>
        {/* UIKit class padding-0 needed for internal css selectors to work */}
        <ApplicationLayout.Body innerClassName={`p-0 padding-0 ${!isSystemOk && 'mt-[45px]'}`}>
          <NotificationsContainer />
          <Outlet />
        </ApplicationLayout.Body>
      </ApplicationLayout>
    </IntlProvider>
  )
}

export default App
