import { ResourcesConfig } from 'aws-amplify'

export const authConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_AUTH_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_AUTH_USER_POOL_CLIENT_ID,
      identityPoolId: import.meta.env.VITE_AUTH_IDENTITY_POOL_ID,
      signUpVerificationMethod: 'code'
    }
  },
  API: {
    GraphQL: {
      endpoint: import.meta.env.VITE_GRAPHQL_ENDPOINT,
      region: 'eu-west-1',
      defaultAuthMode: 'userPool'
    }
  }
}
