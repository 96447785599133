import Dialog from 'components/overlays/Dialog'
import RequestExceptionForm from './RequestExceptionForm'
import { RequestExceptionsFormData } from './types'

interface RequestExceptionDialogProps {
  show: boolean
  isLoading: boolean
  isError: boolean
  onCancel: () => void
  onConfirm: (data: RequestExceptionsFormData) => void
}

const RequestExceptionDialog = ({
  show,
  onCancel,
  onConfirm,
  isLoading
}: RequestExceptionDialogProps) => {
  return (
    <Dialog
      data-testid="request-exception-dialog"
      bodyClassName="p-0"
      show={show}
      title={
        <p className="font-body text-20 font-medium leading-24">
          Create new driving restriction exception request
        </p>
      }
      body={<RequestExceptionForm onSubmit={onConfirm} onCancel={onCancel} isLoading={isLoading} />}
      onClose={onCancel}
      showCloseButton={!isLoading}
      disableEsc={isLoading}
      size="md"
    />
  )
}

export default RequestExceptionDialog
