import RIOButton, { BUTTON_SIZE, BUTTON_STYLE, BUTTON_VARIANT } from '@rio-cloud/rio-uikit/Button'
import classnames from 'classnames'
import { PropsWithChildren } from 'react'
import { PropsWithTestId } from '../types'

interface ButtonProps {
  onClick?: () => void
  variant?: BUTTON_VARIANT
  size?: BUTTON_SIZE
  style?: BUTTON_STYLE
  disabled?: boolean
  className?: string
  type?: 'button' | 'submit'
  dark?: boolean
  fullWidth?: boolean
  isLoading?: boolean
}

/**
 * The Button component is used for actions and links in a page.
 * It's a wrapper around the Button component from rio-uikit
 */
const Button = ({
  onClick,
  variant,
  size,
  style,
  className,
  disabled,
  type,
  dark,
  fullWidth,
  isLoading,
  children,
  'data-testid': dataTestId
}: PropsWithTestId<PropsWithChildren<ButtonProps>>) => {
  return (
    <RIOButton
      data-testid={dataTestId}
      onClick={onClick}
      variant={variant}
      bsSize={size}
      bsStyle={style}
      disabled={disabled}
      className={classnames([
        className,
        { 'btn-loading': isLoading },
        {
          'border-none bg-gray-darker text-gray-light outline-none hover:bg-gray-darkest active:bg-black':
            dark
        },
        {
          'h-fit w-full border-none py-25 shadow-top transition-all hover:shadow-none disabled:opacity-40':
            fullWidth
        }
      ])}
      type={type}
    >
      {children}
    </RIOButton>
  )
}

export default Button
