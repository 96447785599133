import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { fetchUserAttributes } from 'aws-amplify/auth'
import { errorHandler } from './errors'

export const userApi = createApi({
  reducerPath: 'user',
  baseQuery: fakeBaseQuery(),
  endpoints: (builder) => ({
    getUserAttributes: builder.query({
      queryFn: async () => {
        try {
          const userAttributes = await fetchUserAttributes()
          return { data: userAttributes }
        } catch (error) {
          throw errorHandler('Unexpected error occurred while fetching the user info', error)
        }
      }
    })
  })
})

export const { useGetUserAttributesQuery } = userApi
