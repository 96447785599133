import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getIsVehicleDetailsOpen, getSelectedVIN } from 'store/slices/appSlice'
import useSelectedVehicleSubscription from './useSelectedVehicleSubscription'
import { navigateToVehicleDetails } from './utils'

const useMultimonitorSetup = () => {
  const selectedVIN = useSelector(getSelectedVIN)
  const isVehicleDetailPageOpen = useSelector(getIsVehicleDetailsOpen)

  const { data: vehicleData } = useSelectedVehicleSubscription()

  useEffect(() => {
    navigateToVehicleDetails(isVehicleDetailPageOpen, selectedVIN)
  }, [isVehicleDetailPageOpen, selectedVIN, vehicleData?.activeMissionId])
}

export default useMultimonitorSetup
