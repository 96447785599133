import { DEFAULT_LOCALE, extractLanguage } from '../store/i18n/lang'
import { trace } from '../store/setup/trace'
import { store } from '../store/setup'
import { getLocale } from '../store/i18n/langSlice'
import { configureFetchDisplayMessages } from '../store/i18n/services'

export const main = async (renderApp: Function) => {
  const fetchDisplayMessages = configureFetchDisplayMessages(store)

  // We want the `<html lang>` attribute to be synced with the
  // language currently displayed
  store.subscribe(() => {
    const lang = extractLanguage(getLocale(store.getState()))
    const html = document.querySelector('html')

    if (html && lang && html.getAttribute('lang') !== lang) {
      html.setAttribute('lang', lang)
    }
  })

  try {
    await fetchDisplayMessages(DEFAULT_LOCALE)
    renderApp()
  } catch (error) {
    trace('could not start application', error)
  }
}

export const getMapCredentials = () => ({
  app_id: '8jeQSGv5cilgzlWhwAJM',
  apikey: import.meta.env.VITE_MAP_SERVICE_API_KEY
})
