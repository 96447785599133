import { FormattedMessage } from 'react-intl'
import { NavLink, Link } from 'react-router-dom'
import MainNavigation from 'components/navigation/MainNavigation'
import { config } from '../../config'
import { ROUTES } from '../../routes/types'
import AccountMenu from './AccountMenu'

const AppHeader = () => {
  const navItems = [
    {
      key: 'Map',
      route: (
        <NavLink to={ROUTES.DEFAULT}>
          <p className="font-display">
            <FormattedMessage id={'intl-msg:control-center.sublink.monitoring'} />
          </p>
        </NavLink>
      )
    }
  ]

  return (
    <MainNavigation
      showHomeIcon={false}
      homeRoute={<Link to={config.homeRoute || ROUTES.DEFAULT} />}
      navItems={navItems}
      actionBarItems={[<AccountMenu key={'account'} />]}
    />
  )
}

export default AppHeader
