import RIOContentLoader from '@rio-cloud/rio-uikit/ContentLoader'

export type ContentLoaderType = typeof RIOContentLoader.CIRCLE | typeof RIOContentLoader.RECT

interface ContentLoaderProps {
  height?: number | string
  width?: number | string
  className?: string
  speed?: number
  radius?: number
  type?: ContentLoaderType
}

const ContentLoader = ({ width, height, className, speed, radius, type }: ContentLoaderProps) => {
  return (
    <RIOContentLoader
      width={width}
      height={height}
      speed={speed}
      radius={radius}
      className={className}
      type={type}
    />
  )
}

export default ContentLoader
