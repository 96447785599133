import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store/reducers'

export interface AssignMissionState {
  confirmationDialogOpen: boolean
  isErrorDialogOpen: boolean
}

const initialState: AssignMissionState = {
  confirmationDialogOpen: false,
  isErrorDialogOpen: false
}

export const assignMissionSlice = createSlice({
  name: 'assignMission',
  initialState,
  reducers: {
    setConfirmationDialogOpen: (state: AssignMissionState, action: PayloadAction<boolean>) => {
      state.confirmationDialogOpen = action.payload
    },
    setIsErrorDialogOpen: (state: AssignMissionState, action: PayloadAction<boolean>) => {
      state.isErrorDialogOpen = action.payload
    }
  }
})

export const { setConfirmationDialogOpen, setIsErrorDialogOpen } = assignMissionSlice.actions

export const getConfirmationDialogOpen = (state: RootState) =>
  state.assignMission.confirmationDialogOpen
export const getIsErrorDialogOpen = (state: RootState) => state.assignMission.isErrorDialogOpen

export default assignMissionSlice.reducer
