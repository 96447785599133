export const uncaughtErrorMessage = 'Uncaught Error. An unexpected error occurred.'

export const getMessageFromError = (errorData: unknown): string | undefined => {
  if (!errorData) {
    return uncaughtErrorMessage
  }

  switch (typeof errorData) {
    case 'string':
      return errorData

    case 'object':
      if (!errorData) {
        return uncaughtErrorMessage
      }

      if (Array.isArray(errorData)) {
        return errorData.map(getMessageFromError).join('\n')
      }

      if ('message' in errorData && typeof errorData.message === 'string') {
        return errorData.message
      }

      if ('errors' in errorData && Array.isArray(errorData.errors)) {
        return errorData.errors.map(getMessageFromError).join('\n')
      }

      return uncaughtErrorMessage

    default:
      return uncaughtErrorMessage
  }
}

export const propagateErrorMessage = (customMessage?: string, errorData?: unknown) => {
  const errorMessage = getMessageFromError(errorData)
  switch (true) {
    case !!customMessage && !errorData:
      return customMessage
    case !customMessage && !!errorData:
      return errorMessage
    case !customMessage && !errorData:
      return uncaughtErrorMessage
    case !!customMessage && !!errorData:
      return [customMessage, errorMessage].join('\n')
    default:
      return uncaughtErrorMessage
  }
}

export class AppError extends Error {
  static fromError(customMessage: string, errorData?: unknown): AppError {
    const errorMessage = propagateErrorMessage(customMessage, errorData) ?? customMessage
    return new AppError(errorMessage, errorData)
  }

  override message: string
  errorData?: unknown

  constructor(message: string, errorData?: unknown) {
    super(message)

    this.message = message
    this.errorData = errorData
  }
}

export const errorHandler = (message: string, errorData: unknown) => {
  const error = errorData instanceof Error ? errorData : AppError.fromError(message, errorData)
  console.error('[ErrorHandler]', {
    ...error,
    message: error.name === 'ZodError' ? JSON.parse(error.message) : error.message
  })
  return error
}
