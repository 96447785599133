import { RoadSegmentDirection, RoadSegmentRestrictionCause } from 'API'

export const mapExceptionType = (exceptionType?: RoadSegmentRestrictionCause): string => {
  return {
    [RoadSegmentRestrictionCause.ACCIDENT]: 'Accident',
    [RoadSegmentRestrictionCause.CONGESTION]: 'Congestion',
    [RoadSegmentRestrictionCause.OBSTRUCTION]: 'Obstruction',
    [RoadSegmentRestrictionCause.ROADWORK]: 'Roadwork',
    [RoadSegmentRestrictionCause.ROAD_CLOSURE]: 'Road closure',
    [RoadSegmentRestrictionCause.GENERAL]: 'General'
  }[exceptionType || RoadSegmentRestrictionCause.GENERAL]
}

export type TableHeaders = Record<AllowedTableHeaders, string>
export const mapDirection = (direction?: RoadSegmentDirection): string => {
  return {
    [RoadSegmentDirection.MAIN]: 'Main',
    [RoadSegmentDirection.OPPOSITE]: 'Opposite'
  }[direction || RoadSegmentDirection.MAIN]
}

// ============================================= Types ==================================================

type AllowedTableHeaders =
  | 'id'
  | 'createdAt'
  | 'exceptionType'
  | 'periodStart'
  | 'periodEnd'
  | 'from'
  | 'to'
  | 'direction'
  | 'status'
  | '_'
