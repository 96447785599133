import { DatePicker } from '@rio-cloud/rio-uikit'
import moment, { Moment } from 'moment'
import { Controller } from 'react-hook-form'
import { FormFieldProps } from './types'

const FormFieldDateTimeInput = ({
  placeholder,
  name,
  error,
  label,
  control,
  required,
  onChange
}: FormFieldProps & { onChange: () => void }) => (
  <div className={`form-group mb-0 [&_.form-group]:mb-0 ${error && 'has-error has-feedback'}`}>
    <label htmlFor={name}>{label}</label>
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <DatePicker
          id={name}
          inputProps={{ placeholder }}
          dateFormat="DD/MM/YYYY"
          timeFormat
          mandatory={required}
          isValidDate={(current, selected) =>
            current.isSame(selected, 'day') || current.isAfter(selected, 'day')
          }
          // moment used by RIO component, available via peer dependency
          onChange={(result: Moment | string) => {
            const date = moment.isMoment(result) ? result.toISOString() : result
            field.onChange(date)
            onChange()
          }}
        />
      )}
    />

    {error && (
      <>
        <span className="form-control-feedback rioglyph rioglyph-error-sign" />
        <span className="help-block">
          <span>{error.message}</span>
        </span>
      </>
    )}
  </div>
)
export default FormFieldDateTimeInput
