import Dialog from '@rio-cloud/rio-uikit/Dialog'
import Button from '@rio-cloud/rio-uikit/Button'
import { DialogProps } from 'helper/types'

const Title = () => (
  <p className="font-body text-20 font-medium leading-24">Sorry, something went wrong</p>
)

interface BodyProps {
  errorStatus?: DialogProps['errorStatus']
}

const Body = ({ errorStatus }: BodyProps) => {
  return (
    <div className="text-16">
      <div className="mb-10 flex justify-center">
        <span className="rioglyph rioglyph-truck-breakdown text-[77px] text-gray-light" />
      </div>
      <p className="mb-15">
        For technical reasons, we couldn&apos;t complete the action you tried to perform.
      </p>
      <p>
        Please try or refresh the page. If that doesn&apos;t work, please contact the Control Center
        support team.
      </p>
      <p className="mt-20 text-14 text-gray-dark">Error type {errorStatus}</p>
    </div>
  )
}

interface FooterProps {
  onConfirm: DialogProps['onConfirm']
}

const Footer = ({ onConfirm }: FooterProps) => {
  return (
    <div className="flex justify-end gap-10">
      <Button data-testid="error-dialog-close-button" onClick={onConfirm} bsStyle={Button.PRIMARY}>
        Close
      </Button>
    </div>
  )
}

const ErrorDialog = ({ show, onConfirm, errorStatus = 500 }: DialogProps) => {
  return (
    <Dialog
      data-testid="error-dialog"
      show={show}
      title={<Title />}
      footer={<Footer onConfirm={onConfirm} />}
      body={<Body errorStatus={errorStatus} />}
      onClose={onConfirm}
      showCloseButton
      bsSize={Dialog.SIZE_SM}
    />
  )
}

export default ErrorDialog
