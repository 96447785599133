import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store/reducers'

export interface RequestExeceptionState {
  isDialogOpen: boolean
  isErrorDialogOpen: boolean
}

const initialState: RequestExeceptionState = {
  isDialogOpen: false,
  isErrorDialogOpen: false
}

export const requestExceptionsSlice = createSlice({
  name: 'requestExceptions',
  initialState,
  reducers: {
    setIsDialogOpen: (state: RequestExeceptionState, action: PayloadAction<boolean>) => {
      state.isDialogOpen = action.payload
    },
    setIsErrorDialogOpen: (state: RequestExeceptionState, action: PayloadAction<boolean>) => {
      state.isErrorDialogOpen = action.payload
    }
  }
})

export const { setIsDialogOpen, setIsErrorDialogOpen } = requestExceptionsSlice.actions

export const getIsDialogOpen = (state: RootState) => state.requestExceptions.isDialogOpen
export const getIsErrorDialogOpen = (state: RootState) => state.requestExceptions.isErrorDialogOpen

export default requestExceptionsSlice.reducer
