export type Locale = 'de' | 'en' | 'de-DE' | 'en-GB'
export const DEFAULT_LOCALE: Locale = 'en-GB'

export type LocaleMap = Record<Locale, Locale>

export const supportedLocaleMap: LocaleMap = {
  de: 'de-DE',
  'de-DE': 'de-DE',
  en: 'en-GB',
  'en-GB': 'en-GB'
}

export const extractLanguage = (value: Locale | null | undefined) => {
  const head = (val: string) => val.split('-')[0]
  return value ? head(value) : head(DEFAULT_LOCALE)
}

export const DEFAULT_LANG = extractLanguage(DEFAULT_LOCALE)

export const getSupportedLocale = (preferredLocale: Locale) =>
  Object.keys(supportedLocaleMap).includes(preferredLocale)
    ? supportedLocaleMap[preferredLocale]
    : DEFAULT_LOCALE
