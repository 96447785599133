import { Store } from '@reduxjs/toolkit'
import { displayMessagesFetched, localeChanged } from './langSlice'
import { DEFAULT_LOCALE, Locale, getSupportedLocale as defaultGetSupportedLocale } from './lang'
import { trace } from '../setup/trace'

const sendError = (exception: unknown) => {
  // TODO: MTBCCA-603: add Datadog
  console.error(exception)
}

// TODO: Find a better typed solution to avoid using any
const normalizeDynamicImport = (imported: unknown) => (imported as any).default || imported

export const importDisplayMessages = (locale: string) =>
  import(`../../i18n/${locale}.json`).then(normalizeDynamicImport).catch((error) => {
    sendError(error)
    return error
  })

export const configureFetchDisplayMessages =
  (
    store: Store,
    fetchDisplayMessages = importDisplayMessages,
    getSupportedLocale = defaultGetSupportedLocale
  ) =>
  async (locale: Locale) => {
    if (!locale) {
      console.warn('No "locale" supplied when fetching display messages!')
      return Promise.reject()
    }

    const supportedLocale = getSupportedLocale(locale)

    try {
      const displayMessages = await fetchDisplayMessages(supportedLocale)
      trace(`Display messages fetched for "${supportedLocale}"`)
      store.dispatch(displayMessagesFetched({ locale: supportedLocale, displayMessages }))
    } catch (error) {
      console.error(`Display messages for "${supportedLocale}" could not be fetched.`, error)
      sendError(error)
      store.dispatch(localeChanged(DEFAULT_LOCALE))
    }
  }
