import Spinner from 'components/feedback/Spinner'

const DeviationListSpinner = () => {
  return (
    <div className="flex h-[90%] items-center justify-center" data-testid="deviations-list-spinner">
      <Spinner large borderWidth={8} size={200} />
    </div>
  )
}

export default DeviationListSpinner
