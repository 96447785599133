import { createListenerMiddleware } from '@reduxjs/toolkit'
import { store } from '.'
import { missionApi } from 'services/mission'
import { vehiclesApi } from 'services/vehicles'
import { clearSelectedVIN, setSelectedVehicle } from 'store/slices/appSlice'

export const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  actionCreator: clearSelectedVIN,
  effect: async (_, listenerApi) => {
    listenerApi.dispatch(missionApi.util.resetApiState())
  }
})

listenerMiddleware.startListening({
  actionCreator: setSelectedVehicle,
  effect: async (action, listenerApi) => {
    const selectedVIN = action.payload.selectedVIN

    // Check if there is query data for a vehicle subscription mathcing the selected VIN
    const vehicles = vehiclesApi.endpoints.onUpdateVehicleSubscription.select('ListVehicles')(
      store.getState()
    ).data

    if (!!vehicles) {
      // Check if there is a mission assigned to the selected vehicle
      const hasMission = selectedVIN && !!vehicles[selectedVIN]?.activeMissionId

      if (!hasMission) {
        // if the currently selected vehicle (or deselected) does not have a mission
        // reset the missionAPI state, so cache entries are removed an missionAPI unsubscribes (closes) from the websocket
        listenerApi.dispatch(missionApi.util.resetApiState())
      }
    }
  }
})
