import NotificationsContainer from 'components/content/Notifications'
import ApplicationLayout from 'components/ApplicationLayout'
import { Outlet } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { DEFAULT_LOCALE } from 'store/i18n/lang'
import { getDisplayMessages, getLocale } from 'store/i18n/langSlice'
import { useAppSelector } from 'store/setup/hooks'
import ExceptionsAppHeader from '../features/exception-requests/ExceptionsAppHeader'

const App = () => {
  const userLocale = useAppSelector(getLocale)
  const displayMessages = useAppSelector(getDisplayMessages)

  if (!displayMessages || !userLocale) {
    return null
  }

  return (
    <IntlProvider
      defaultLocale={DEFAULT_LOCALE}
      key={userLocale}
      locale={userLocale}
      messages={displayMessages}
    >
      <ApplicationLayout>
        <ApplicationLayout.Header>
          <ExceptionsAppHeader />
        </ApplicationLayout.Header>
        {/* UIKit class padding-0 needed for internal css selectors to work */}
        <ApplicationLayout.Body innerClassName="p-0 padding-0">
          <NotificationsContainer />
          <Outlet />
        </ApplicationLayout.Body>
      </ApplicationLayout>
    </IntlProvider>
  )
}

export default App
