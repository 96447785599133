import { useMemo } from 'react'
import { MRMStateValue } from 'API'

const useMRMProcess = (mrmStateValue?: MRMStateValue | null) => {
  const { isPerformingMRM, isMRC, isReleasingMRC } = useMemo(
    () => ({
      isPerformingMRM: mrmStateValue === MRMStateValue.EXECUTING_MRM,
      isMRC: mrmStateValue === MRMStateValue.MRC,
      isReleasingMRC: mrmStateValue === MRMStateValue.RELEASING_MRC
    }),
    [mrmStateValue]
  )

  const isLoading = isPerformingMRM || isReleasingMRC

  if (!mrmStateValue || mrmStateValue === MRMStateValue.NONE) {
    return {
      isMRC: false,
      isPerformingMRM: false,
      isReleasingMRC: false,
      isLoading: false
    }
  }

  return {
    isMRC,
    isPerformingMRM,
    isReleasingMRC,
    isLoading
  }
}

export default useMRMProcess
