import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store/reducers'

export interface ResetMissionState {
  confirmationDialogOpen: boolean
  errorDialogOpen: boolean
  isAwaitingResponse: boolean
}

const initialState: ResetMissionState = {
  confirmationDialogOpen: false,
  errorDialogOpen: false,
  isAwaitingResponse: false
}

export const resetMissionSlice = createSlice({
  name: 'resetMission',
  initialState,
  reducers: {
    setConfirmationDialogOpen: (state: ResetMissionState, action: PayloadAction<boolean>) => {
      state.confirmationDialogOpen = action.payload
    },
    setErrorDialogOpen: (state: ResetMissionState, action: PayloadAction<boolean>) => {
      state.errorDialogOpen = action.payload
    },
    setIsAwaitingResponse: (state: ResetMissionState, action: PayloadAction<boolean>) => {
      state.isAwaitingResponse = action.payload
    }
  }
})

export const { setConfirmationDialogOpen, setIsAwaitingResponse, setErrorDialogOpen } =
  resetMissionSlice.actions

export const getConfirmationDialogOpen = (state: RootState) =>
  state.resetMission.confirmationDialogOpen
export const getErrorDialogOpen = (state: RootState) => state.resetMission.errorDialogOpen
export const getIsAwaitingResponse = (state: RootState) => state.resetMission.isAwaitingResponse

export default resetMissionSlice.reducer
