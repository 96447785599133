import { MRMState } from 'API'
import { DeviationWithRelativeTime } from './VehicleDeviation'
import CommandProgressIcon from './CommandProgressIcon'
import VehicleCard from 'components/feedback/VehicleCard'

interface VehicleDeviationCardProps {
  data: DeviationWithRelativeTime
  isSelected: boolean
  mrmState?: MRMState | null
  onClick: () => void
  vehicleIdentifier: string
  className?: string
}

const VehicleDeviationCard = ({
  data,
  vehicleIdentifier,
  isSelected,
  onClick,
  className,
  mrmState
}: VehicleDeviationCardProps) => {
  return (
    <VehicleCard
      className={className}
      isSelected={isSelected}
      severity={data.severity}
      description={data.description}
      relativeTime={data.relativeTime}
      vehicleIdentifier={vehicleIdentifier}
      onClick={onClick}
      icon={
        !!mrmState && (
          <div className="absolute right-0 top-0 p-15">
            <CommandProgressIcon mrmState={mrmState} />
          </div>
        )
      }
    />
  )
}

export default VehicleDeviationCard
