import { Theme } from '@aws-amplify/ui-react'
import tailwind from '../tailwind.config'

// Custom theme for Amplify to match Traton theme
export const amplifyTheme: Theme = {
  name: 'custom-theme',
  tokens: {
    fonts: {
      default: {
        variable: { value: 'TRATON Type Text, "serif"' },
        static: { value: 'TRATON Type Text, "serif"' }
      }
    },
    components: {
      button: {
        primary: {
          backgroundColor: {
            value: tailwind.theme.colors.primary
          },
          _hover: {
            backgroundColor: {
              value: tailwind.theme.colors.highlight.dark
            }
          }
        },
        fontWeight: {
          value: 400
        }
      }
    }
  }
}
