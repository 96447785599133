import { Vehicle } from 'API'
import { getSelectedVIN } from 'store/slices/appSlice'
import { getVehicleByVin } from 'helper/vehicle'
import { useSelector } from 'react-redux'
import { useOnUpdateVehicleSubscriptionQuery } from 'services/vehicles'

interface Props {
  vin?: Vehicle['vin']
}
const useSelectedVehicleSubscription = ({ vin }: Props = {}) => {
  const selectedVIN = useSelector(getSelectedVIN)
  const usableVin = vin || selectedVIN
  return useOnUpdateVehicleSubscriptionQuery('ListVehicles', {
    refetchOnMountOrArgChange: true,
    selectFromResult: (_) => {
      return {
        ..._,
        data: usableVin ? getVehicleByVin(_.currentData, usableVin) : undefined
      }
    }
  })
}

export default useSelectedVehicleSubscription
