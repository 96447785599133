import { useEffect, useState } from 'react'
import clock from 'worker/clock'
import * as Comlink from 'comlink'

export const useClock = () => {
  const [datetime, setDatetime] = useState(new Date())
  useEffect(() => {
    clock.subscribe(Comlink.proxy(setDatetime))
  }, [])
  return datetime
}
