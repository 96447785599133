import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store/reducers'

export interface CancelMissionState {
  confirmationDialogOpen: boolean
  errorDialogOpen: boolean
  isAwaitingResponse: boolean
}

const initialState: CancelMissionState = {
  confirmationDialogOpen: false,
  errorDialogOpen: false,
  isAwaitingResponse: false
}

export const cancelMissionSlice = createSlice({
  name: 'cancelMission',
  initialState,
  reducers: {
    setConfirmationDialogOpen: (state: CancelMissionState, action: PayloadAction<boolean>) => {
      state.confirmationDialogOpen = action.payload
    },
    setErrorDialogOpen: (state: CancelMissionState, action: PayloadAction<boolean>) => {
      state.errorDialogOpen = action.payload
    },
    setIsAwaitingResponse: (state: CancelMissionState, action: PayloadAction<boolean>) => {
      state.isAwaitingResponse = action.payload
    }
  }
})

export const { setConfirmationDialogOpen, setIsAwaitingResponse, setErrorDialogOpen } =
  cancelMissionSlice.actions

export const getConfirmationDialogOpen = (state: RootState) =>
  state.cancelMission.confirmationDialogOpen
export const getErrorDialogOpen = (state: RootState) => state.cancelMission.errorDialogOpen
export const getIsAwaitingResponse = (state: RootState) => state.cancelMission.isAwaitingResponse

export default cancelMissionSlice.reducer
