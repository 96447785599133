import { PropsWithChildren, useEffect } from 'react'
import classnames from 'classnames'

export interface ErrorFallbackProps extends PropsWithChildren {
  error: unknown
  containerClassName?: string
}

const ErrorFallback = ({ error, containerClassName }: ErrorFallbackProps) => {
  useEffect(() => {
    console.error('[ErrorFallback]:', error)
  }, [error])

  return (
    <div className={classnames('flex flex-col p-4', containerClassName)}>
      <div className="alert alert-danger">
        <div className="flex gap-10">
          <span className="rioglyph rioglyph-error-sign text-h4 text-danger" />
          <div>
            <p className="text-16 font-bold">Something went wrong</p>
            <p className="mb-15 mt-5 text-14">
              If this issue persists, please contact the Control Center support team.
            </p>
            <div className="text-14">
              <button onClick={() => location.reload()} type="button" className="btn bg-danger">
                <span className="rioglyph rioglyph-repeat" aria-hidden="true" />
                <p className="ml-5 text-14">Refresh page</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorFallback
