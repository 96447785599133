import { Marker, SingleMapMarker } from 'components/map/Map'
import { Hub } from 'API'
import { transformToRioPosition } from 'helper/position'

interface HubMarkerProps {
  hub: Hub
}

const HubComponent = ({ hub }: HubMarkerProps) => (
  <Marker
    key={hub.id + hub.name}
    customData={hub}
    position={transformToRioPosition(hub.position)}
    icon={
      <SingleMapMarker
        clickable={false}
        name={hub.name}
        iconNames={['factory']}
        markerColor="bg-map-marker-poi"
      />
    }
  />
)

export default HubComponent
