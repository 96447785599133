import RIODialog, { DialogSize as RIODialogSize } from '@rio-cloud/rio-uikit/Dialog'
import React from 'react'
import { PropsWithTestId } from '../types'

type DialogSize = RIODialogSize

interface DialogProps {
  show: boolean
  title?: React.ReactNode
  body?: React.ReactNode
  footer?: React.ReactNode
  showCloseButton?: boolean
  size?: DialogSize
  disableEsc?: boolean
  bodyClassName?: string
  onClose?: () => void
}

const Dialog = ({
  show,
  body,
  title,
  footer,
  onClose,
  showCloseButton,
  disableEsc,
  size = RIODialog.SIZE_SM,
  bodyClassName,
  'data-testid': dataTestId
}: PropsWithTestId<DialogProps>) => {
  return (
    <RIODialog
      data-testid={dataTestId}
      show={show}
      title={title}
      footer={footer}
      body={body}
      onClose={onClose}
      showCloseButton={showCloseButton}
      bsSize={size}
      bodyClassName={bodyClassName}
      disableEsc={disableEsc}
    />
  )
}

export default Dialog
